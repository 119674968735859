.app-runner-frame {
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
  & > body {
    margin: 0;
  }
}

.header {
  z-index: 999;
  position: absolute;
  background-color: rgba(79, 51, 51, 0.3);;
  width: 100%;
  height: calc(100% - 10px);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: larger;
  padding: 30px;
  border: 2px solid black;
  border-radius: 25px;
  background-color: aliceblue;
}

.loading-message {
  position: relative;
  top: -17px;
  left: 10px;
}